import { transportation } from '@/axios'

export default {
  uploadRequiredDocsFiles (payload) {
    return transportation().patch('drivers', payload)
  },
  getRequirementsDocuments () {
    return transportation().get('required-docs?filters[type]=driver')
  }
}
