<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false">
     اضاقة شاحنة
    </dashboard-page-title>

    <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(addVehicle)">
          <b-row>
          <b-col md="8">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> معلومات اساسية</h5>
                </template>
                <b-row>
                  <b-col  cols="4">
                    <input-form
                        class="mb-3"
                        v-model="vehicle.serialNumber"
                        validate="required"
                        name="id"
                        :disabled="routeName === 'showVehicle'"
                        placeholder="أدخل الاسم" label="رقم الشاسيه"
                    />
                  </b-col>
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="vehicle.model"
                        :validate="'required'"
                        name="موديل"
                        :disabled="routeName === 'showVehicle'"
                        placeholder="اسم موديل" label="موديل"
                    />
                  </b-col>
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="vehicle.plateNumber"
                        :validate="'required'"
                        name="رقم اللوحة"
                        :disabled="routeName === 'showVehicle'"
                        placeholder="رقم اللوحة" label="رقم اللوحة"
                    />
                  </b-col>
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="vehicle.year"
                        type="number"
                        :validate="'required|numeric'"
                        name="السنه"
                        :disabled="routeName === 'showVehicle'"
                        placeholder="Ex: 2012" label="السنه"
                    />
                  </b-col>
                  <b-col md="4">
                    <label class="font-size-14">اقسام الشاحنات</label>
                    <main-select
                        :reduce="category => category.id"
                        :options="vehicleType"
                        v-model="vehicle.vehicleTypeId"
                        label="name"
                        dir="rtl"
                    />
                    <!--                      :placeholder="$t('endUser.storeType')"-->
                  </b-col>
                  <b-col md="4">
                    <label class="font-size-14">انواع الشاحنات</label>
                    <main-select
                        :reduce="category => category.id"
                        :options="vehicleMake"
                        v-model="vehicle.vehicleMakeId"
                        label="name"
                        dir="rtl"
                    />
                    <!--                      :placeholder="$t('endUser.storeType')"-->
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-image"></i>الصور</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="صورة الشاحنه الرئيسية"
                        nameOfImage="image.jpg"
                        @cropper-save="saveIdImage"
                        :progressLoading="progressIdImage"
                        :multi="false"
                        :imageUrl="vehicle.featuredImage"
                    />
                  </b-col>
                  <b-col md="12" class="mb-3">
                    <cropper-images
                        label="رفع الصور المتبقية"
                        @cropper-save="saveGalleryImage"
                        :progressLoading="loadingGallery"
                        :removeLoadingUi="removeLoadingUi"
                        :images="vehicle.images"
                    ></cropper-images>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
          <b-col md="3">
            <b-card class="iq-border-radius-10 mb-3 main-actions">
              <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-save"></i>
                  النشر
                </h5>
              </template>
              <div v-if="loadingSubmitForm">
                <b-button variant="primary" class="w-100" disabled>
                  <spinner-loading text="يتم الحفظ"></spinner-loading>
                </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                <b-button variant="primary"  class="w-100" type="submit" > حفظ </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
        </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
// import settingsServices from '../services/settings'
import mainInfoMixins from '@/mixins/mainInfo'
import transportationServices from '@/modules/Driver/vehicles/services/vehicles'
export default {
  mixins: [mainInfoMixins],
  components: { },
  mounted () {
    core.index()
  },
  data () {
    return {
      routeName: this.$route.name,
      vehicleMake: [],
      vehicleType: [],
      vehicle: {
        serialNumber: '',
        model: '',
        plateNumber: '',
        featuredImage: '',
        year: '',
        vehicleMakeId: '',
        vehicleTypeId: '',
        status: 'processing',
        images: []
      },
      loadingSubmitForm: false,
      loadingGallery: 0,
      removeLoadingUi: false,
      progressIdImage: 0
    }
  },
  methods: {
    getAllVehicleType () {
      transportationServices.getVehiclesType().then(res => {
        this.vehicleType = res.data.data
      })
    },
    getAllVehicleMake () {
      transportationServices.getVehiclesMake().then(res => {
        this.vehicleMake = res.data.data
      })
    },
    saveIdImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'transportation')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressIdImage = percent
        }
      }
      this.commonUploadFilesImage(formData, options).then(res => {
        this.vehicle.featuredImage = res.data
        this.showSuccessUploadFile()
      }).catch(err => {
        console.log(err)
      })
    },
    saveGalleryImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'transportation')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingGallery = percent
        }
      }
      this.commonUploadFilesImage(formData, options).then(res => {
        this.vehicle.images.push(res.data)
        this.showSuccessUploadFile()
      })
    },
    // saveIdImage (file) {
    //   const formData = new FormData()
    //   formData.append('image_name', file.imageInfo.name)
    //   formData.append('directory', 'Vehicles')
    //   formData.append('file', file.image)
    // formData.append('app_name', 'transportation')
    //   const options = {
    //     onUploadProgress: (progressEvent) => {
    //       const { loaded, total } = progressEvent
    //       const percent = Math.floor((loaded * 100) / total)
    //       this.progressIdImage = percent
    //     }
    //   }
    //   this.commonUploadFiles(formData, options).then(res => {
    //     this.vehicle.featuredImage = res.data
    //     this.showSuccessUploadFile()
    //   })
    // },
    // saveGalleryImage (file) {
    //   const formData = new FormData()
    //   formData.append('image_name', file.imageInfo.name)
    //   formData.append('directory', 'Vehicles')
    //   formData.append('image', file.image)
    //   const options = {
    //     onUploadProgress: (progressEvent) => {
    //       const { loaded, total } = progressEvent
    //       const percent = Math.floor((loaded * 100) / total)
    //       this.loadingGallery = percent
    //     }
    //   }
    //   this.commonUploadFiles(formData, options).then(res => {
    //     this.vehicle.images.push(res.data)
    //     this.showSuccessUploadFile()
    //   })
    // },
    addVehicle () {
      console.log(this.vehicle)
      this.loadingSubmitForm = true
      transportationServices.addVehicle(this.vehicle).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'driverVehicles' })
      }).finally(() => {
        this.loadingSubmitForm = false
      })
    }
  },
  created () {
    this.getAllVehicleType()
    this.getAllVehicleMake()
  }
}
</script>

<style scoped>

</style>
